import React, {useEffect, useRef, useState} from "react";
import {Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent,} from "@mui/material";
import {useLocalization} from "../../hooks";
import {useTranslation} from "react-i18next";

const languages = [
  { code: "en", name: "English" },
  { code: "pt", name: "Português" },
  { code: "zh", name: "Mandarin" },
];

export const LanguageDropdown = () => {
  const { languageCode, initializeLanguage } = useLocalization();
  const { i18n, t } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(languageCode);

  useEffect(() => {
    if (languageCode !== selectedLanguage) {
      setSelectedLanguage(languageCode);
    }
  }, [languageCode, i18n.language]);

  const handleLanguageChange = async (event: SelectChangeEvent<string>) => {
    const newLanguage = event.target.value;
    setSelectedLanguage(newLanguage);
    await initializeLanguage(newLanguage);
  };
  return (
    <FormControl
      variant="outlined"
      size="small"
      key={`lang-select-${selectedLanguage}`}
    >
      <InputLabel id="language-select-label">{t("Language")}</InputLabel>
      <Select
        labelId="language-select-label"
        value={selectedLanguage}
        onChange={handleLanguageChange}
        label="Language"
      >
        {languages.map((lang) => (
          <MenuItem
            key={lang.code}
            value={lang.code}
            style={{ textAlign: "center" }}
          >
            <Box
              component="img"
              src={`/assets/logos/${lang.code}.png`}
              alt={`${lang.name} flag`}
              style={{ width: "35px", height: "20px", marginRight: "8px" }}
            />
            {lang.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default LanguageDropdown;

// export const LanguageDropdownStyles = makeStyles()((theme) => {
//   return {
//     LanguageDropDown: {
//       position: "relative",
//       right: "auto",
//       margin: "10px",
//       left: "80%",
//       [theme.breakpoints.between("sm", "md")]: {
//         left: "55%",
//         // display: "none", // Uncomment if you want to hide the element
//       },
//       [theme.breakpoints.down("sm")]: {
//         left: "28%",
//       },
//     },
//   };
// });
