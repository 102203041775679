import React from "react";
import { Close, OpenInNewTwoTone, QrCode } from "@mui/icons-material";
import {
    Box,
    Card,
    Grid,
    IconButton,
    Typography,
    Tooltip as MuiTooltip,
    Dialog,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { truncateAddress } from "app/utils";
import { QRCodeGenerator } from "app/components/qrcode";
import { WalletDetailedDialog } from "./WalletDetailedDialog";
import LanguageIcon from "@mui/icons-material/Language";


const getIcon = (assetId: string) => {
    switch (assetId) {
        case "USDT Etherum":
            return "assets/icons/usdt.svg";
        case "Etherum":
            return "assets/icons/eth.svg";
        case "Bitcoin":
            return "assets/icons/btc.svg";
        default:
            return "assets/icons/eth.svg";
    }
};


export const WalletCard = ({
    data,
    classes,
    handleCopyToClipboard,
}: any) => {

    const [dialogOpen, setDialogOpen] = React.useState(false);
    const [dialogData, setDialogData] = React.useState("");

    const [detailsPageDialogOpen, setDetailsPageDialogOpen] = React.useState(false);
    const [detailsPageDialogData, setDetailsPageDialogData] = React.useState("");

    const handleBlockChainHistory = () => {
    if (!data || !data.asset_name || !data.wallet_address) {
        console.error("Invalid data for blockchain history");
        return;
    }

    const explorers: { [key: string]: string } = {
        Ethereum: `https://etherscan.io/address/${data.wallet_address}`,
        Bitcoin: `https://www.blockchain.com/explorer/search?search=${data.wallet_address}`,
        "USDT Ethereum": `https://etherscan.io/address/${data.wallet_address}`,
        Avalanche: `https://snowtrace.io/address/${data.wallet_address}`,
        "USDT Tether(TRON)": `https://tronscan.org/#/address/${data.wallet_address}`,
        Tron: `https://tronscan.org/#/address/${data.wallet_address}`,
    };

        // Check for exact match in explorers
        if (explorers[data.asset_name]) {
            window.open(explorers[data.asset_name]);
            return;
        }

        // Additional case for substrings (e.g., asset names containing "Avalanche")
        if (data.asset_name.includes("Avalanche")) {
            window.open(`https://snowtrace.io/address/${data.wallet_address}`);
        }
    };


    return (
        <>  
            <WalletDetailedDialog open={detailsPageDialogOpen} onClose={() => setDetailsPageDialogOpen(false)} wallet_id={detailsPageDialogData} />
            <Dialog
                open={dialogOpen}
                onClose={() => {}}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Box
                    sx={{
                        width: "240px",
                        height: "240px",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Box sx={{ marginLeft: "auto" }}>
                        <IconButton onClick={() => setDialogOpen(false)}>
                            <Close />
                        </IconButton>
                    </Box>
                    <Box
                        sx={{
                            marginTop: "auto",
                        }}
                    >
                        <Typography variant="h6" color="primary">
                            Scan QR code to copy
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            marginBottom: "auto",
                        }}
                    >
                        <QRCodeGenerator data={dialogData} />
                    </Box>
                </Box>
            </Dialog>
            <Grid key={data.id}>
                <Card className={classes.walletCards}>
                    <Box className={classes.walletTOpBox}>
                        <Box className={classes.flexDR}>
                            <Box
                                component="img"
                                className={classes.tokenIcons}
                                src={getIcon(data.asset_name)}
                                alt="Descriptive Alt Text"
                            />
                            <Typography
                                variant="h6"
                                color="primary"
                                sx={{ marginLeft: "10px", marginTop: "3px" }}
                            >
                                {data.asset_name}
                            </Typography>
                            <IconButton
                                onClick={() => {
                                    setDetailsPageDialogOpen(true);
                                    setDetailsPageDialogData(data.id);
                                }}
                            >
                                <OpenInNewTwoTone />
                            </IconButton>
                        </Box>
                        <Box sx={{ marginLeft: "auto" }}>
                            <IconButton
                                onClick={() => {
                                    setDialogOpen(true);
                                    setDialogData(data.wallet_address);
                                }}
                            >
                                <QrCode />
                            </IconButton>
                        </Box>
                    </Box>
                    <Box className={classes.walletTOpBox}>
                        <Typography
                            variant="body2"
                            sx={{ margin: "auto 0px", color: "#B9B9B9" }}
                        >
                            {truncateAddress(data.wallet_address)}
                        </Typography>
                        <MuiTooltip
                            title={data.isCopied ? "Copied!" : "Copy"}
                            arrow
                        >
                            <IconButton
                                onClick={() => handleCopyToClipboard(data)}
                            >
                                <ContentCopyIcon />
                            </IconButton>
                        </MuiTooltip>
                    </Box>
                    <Box className={classes.walletTOpBox} mr={2}>
                        <Box className={classes.flexDR}>
                            <Typography color="primary" variant="body2">
                                {data.symbol}
                            </Typography>
                            <Typography
                                sx={{ paddingLeft: "2px", color: "#B9B9B9" }}
                                variant="body2"
                            >
                                {data.currency}
                            </Typography>
                        </Box>
                        <Typography color="primary" variant="h6">
                            {data.balance[0] || 0}
                        </Typography>
                    </Box>

                    <MuiTooltip
                            title={"Transaction History"}
                            arrow
                            className={classes.searchHistory}
                        >
                            <IconButton
                            onClick={handleBlockChainHistory}>
                                <LanguageIcon/>
                            </IconButton>
                        </MuiTooltip>
                </Card>
            </Grid>
        </>
    );
};
