import React, { createContext, useReducer, useContext, ReactNode, Dispatch } from 'react';

interface State {
  vault: {
    index: number;
    id: string;
  };
}

enum ActionType {
  SET_VAULT = 'SET_VAULT',
}

interface Action {
  type: ActionType;
  payload: any;
}

const initialState: State = {
  vault: {
    index: 0,
    id: '',
  },
};

const GlobalStateContext = createContext<{ state: State; dispatch: Dispatch<Action> } | undefined>(undefined);

const globalStateReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SET_VAULT:
      return { ...state, vault: action.payload };
    default:
      return state;
  }
};

interface GlobalStateProviderProps {
  children: ReactNode;
}

export const GlobalStateProvider: React.FC<GlobalStateProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(globalStateReducer, initialState);

  return (
    <GlobalStateContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalStateContext.Provider>
  );
};

export const useGlobalState = () => {
  const context = useContext(GlobalStateContext);
  if (context === undefined) {
    throw new Error('useGlobalState must be used within a GlobalStateProvider');
  }
  return context;
};

export { ActionType };