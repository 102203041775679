// import React, { HTMLProps, JSX, ReactNode } from "react";

import {ThemeProvider} from "@mui/material";
import ReactQueryProvider from "./ReactQueryProvider";
import AuthenticationProvider from "./AuthenticationProvider";
import ApplicationRoutes from "./RoutesProvider";
import {AuthUserContext, AuthUserProvider, T_AppContext} from "./AuthUserProvider";
import {LoaderProvider, useLoader} from "./LoaderProvider";
import {NotificationProvider, useNotification} from "./NotificationProvider";
import React, {HTMLProps, JSX, ReactNode} from "react";
import {BrowserRouter} from "react-router-dom";
import ErrorBoundary from "app/utils/ErrorBoundry";
import { GlobalStateProvider } from "./GlobalStateProvider";

// type AppProviderProps = HTMLProps<JSX.Element> & {
//   theme: any;
// };

// const AppProvider = (props: AppProviderProps): JSX.Element => {
//   return (
//     <ThemeProvider theme={props.theme}>
//       <ErrorBoundary>
//         <NotificationProvider>
//           <LoaderProvider>
//             <ReactQueryProvider>
//               <AuthenticationProvider>
//                 <AuthUserProvider>
//                   <BrowserRouter>
//                     <ApplicationRoutes>{props.children}</ApplicationRoutes>
//                   </BrowserRouter>
//                 </AuthUserProvider>
//               </AuthenticationProvider>
//             </ReactQueryProvider>
//           </LoaderProvider>
//         </NotificationProvider>
//       </ErrorBoundary>
//     </ThemeProvider>
//   );
// };

// export { AppProvider, AuthUserContext, useLoader, useNotification };
// export type {T_AppContext};


type AppProviderProps = HTMLProps<JSX.Element> & {
  theme: any;
};

const providers = [
  ErrorBoundary,
  NotificationProvider,
  LoaderProvider,
  ReactQueryProvider,
  AuthenticationProvider,
  AuthUserProvider,
  BrowserRouter,
  GlobalStateProvider,
];

const AppProvider = (props: AppProviderProps): JSX.Element => {
  const { theme, children } = props;

  const CombinedProviders = ({ children }: { children: ReactNode }) =>
    providers.reduce(
      (acc, Provider) => <Provider>{acc}</Provider>,
      <ApplicationRoutes>{children}</ApplicationRoutes>
    );

  return (
    <ThemeProvider theme={theme}>
      <CombinedProviders>{children}</CombinedProviders>
    </ThemeProvider>
  );
};

export { AppProvider, AuthUserContext, useLoader, useNotification };
export type { T_AppContext };
