import React, { useEffect, useState } from "react";
import { Box, Button, Divider, Typography } from "@mui/material";
import { T_UseFlexhubPaymentState } from "./FlexhubPaymentHooks";
import { useFlexhubTransactionRequest } from "./FlexhubPaymentQueries";
import { CopyToClipboardButton } from "app/components/inputs/buttons";
import { useTranslation } from "react-i18next";

const QRCode = require("qrcode");

const QRCodeGenerator = ({ data }: any) => {
  const [qrCodeURL, setQRCodeURL] = useState("");

  useEffect(() => {
    generateQRCode(JSON.stringify(data.fireblocks_wallet_address));
  }, [data]);

  const generateQRCode = (text: any) => {
    QRCode.toDataURL(text, (err: any, url: string) => {
      if (err) {
        console.log("Error in generating QR code");
      } else {
        setQRCodeURL(url);
      }
    });
  };

  return (
    <div>
      {qrCodeURL && (
        <img
          style={{ width: "200px", height: "200px" }}
          src={qrCodeURL}
          alt="qr code"
        />
      )}
    </div>
  );
};


type T_ViewProps = {
  classes: any;
  paymentState: T_UseFlexhubPaymentState;
  transactionId: string | any;
};


export const TransactionView = ({
  classes,
  paymentState,
  transactionId,
}: T_ViewProps) => {
  const { t } = useTranslation();
  // const transactionRequest = useFlexhubTransactionRequest(transactionId);

  // if (transactionRequest.isResolving) {
  //   return <Loader isLoading={true} />;
  // }

  const handleOnNext = () => {
    paymentState.update({
      isTransactionCompleted: true,
    });
  };

  console.log(paymentState.transactionLink);

  return (
    <Box className={classes.mainCard}>
      <Box className={classes.innerCard}>
        <Box className={classes.midBoxC1}>
          <Box className={classes.cryptoBox}>
            <Typography variant="h4">
              {t("Please transfer the funds you wish to be credited.")}
            </Typography>
          </Box>
        </Box>
        <Divider className={classes.divider} />
        <Box className={classes.midBoxC1}>
          <Box className={classes.cryptoBox}>
            <Typography variant="h4">
              {t("Asset Selected")} : {paymentState.transactionLink.asset}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.midBoxC1}>
          <Box className={classes.cryptoBox}>
            <Typography variant="h4">
              {t("Network")} : {paymentState.transactionLink.parent_asset || paymentState.transactionLink.asset} 
            </Typography>
          </Box>
        </Box>
        <QRCodeGenerator data={paymentState.transactionLink} />
        <Box className={classes.addressBox}>
          <Typography variant="h6">
            {" "}
            {t("One - time wallet address")}{" "}
          </Typography>
          <Box className={classes.addressBox1} gap={1}>
            <Typography variant="h6" className={classes.addressText}>
              {" "}
              {paymentState.transactionLink?.fireblocks_wallet_address || ""}
            </Typography>
            <CopyToClipboardButton
              fieldName={"Copy"}
              fieldValue={
                paymentState.transactionLink?.fireblocks_wallet_address || ""
              }
            />
          </Box>
        </Box>
        <Button
          className={classes.doneButton}
          onClick={() => {
            handleOnNext();
          }}
        >
          {t("Next")}
        </Button>
      </Box>
    </Box>
  );
};
