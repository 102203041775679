import React, { useState } from "react";
import { Grid } from "@mui/material";
import { makeStyles } from "tss-react/mui";
import { pallete, styles } from "app/styles";
import { T_UseAccountStatus } from "app/hooks";
import { ApiConfig } from "app/config";
import { useNotification } from "app/providers";
import {
  KycStatusView,
  SumsubKycProcessView,
  T_OnExitParams,
} from "app/components/widgets/kyc";

export type T_KycState = {
  isInitiated: boolean;
  isSubmitted: boolean;
};

const IdentityVerification = ({  accountStatus,  updateAccountStatus,}: T_UseAccountStatus) => {
  const { classes } = useStyles();
  const notification = useNotification();
  const [kycState, setKycState] = useState<T_KycState>({
    isInitiated: false,
    isSubmitted: false,
  });
  const initKycApiConfig = ApiConfig.create("/verification/kyc-initiate");
  const kycStatusApiConfig = ApiConfig.create("/verification/kyc-result");

  const kycOnInitHandler = () => {
    setKycState({ isInitiated: true, isSubmitted: false });
  };

  const kycIsCompletedHandler = () => {
    //updateAccountStatus({ isKycCompleted: true });
  };

  const kycOnExitHandler = (status: T_OnExitParams): void => {
    console.log({ status });
    if (status.error) {
      notification.error(status.error);
    }
    setKycState({
      isSubmitted: status.isSubmitted,
      isInitiated: status.isInitiated,
    });
  };

  const LivelinessVerificationView = (): React.JSX.Element => {
    if (kycState.isInitiated && !kycState.isSubmitted) {
      return (
        <SumsubKycProcessView
          onExitHandler={kycOnExitHandler}
          initKycApiConfig={initKycApiConfig}
        />
      );
    }
    return (
      <KycStatusView
        {...{
          kycOnInitHandler,
          kycIsCompletedHandler,
          apiConfig: kycStatusApiConfig,
        }}
      />
    );
  };

  return (
    <Grid item md={6} className={classes.rightGrid}>
      <LivelinessVerificationView />
    </Grid>
  );
};

export default IdentityVerification;
const useStyles = makeStyles()((theme) => {
  return {
    label: {
      // Custom styles for the label
      color: "#B9B9B9 !important", // Change label text color
      // fontSize: '1rem', // Change font size
      "&.Mui-focused": {
        color: "blue", // Color of the label when the input is focused
      },
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    createPasswordImg: {},
    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    inputBoxes: {
      // backgroundColor:"pink",
      // height:"40px",
      // width:"40px",
      padding: "10px",
      margin: "20px 10px",
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    orBox: {
      display: "flex",
      flexFlow: "row",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
    },
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    rightGrid: {
      padding: "30px 100px",
      width: "100%",
      [theme.breakpoints.down("lg")]: {
        // height: "100%",
        padding: "30px 15px",
      },
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    mainGrid: {
      height: "100vh",
      width: "100%",
    },
    formBox: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
      alignItems: "center",
      position: "relative",
    },
    leftGrid: {
      padding: "30px 100px",
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100%", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  };
});
