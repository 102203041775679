import { makeStyles, pallete, styles } from "app/styles";

const useCryptohubPaymentStyles = makeStyles()((theme) => {
  return {
    crossIconBtn: {
      position: "absolute",
      top: "20px",
      right: "35px",
    },
    feeBox: {
      ...styles.flexDRS,
      padding: "5px 0",
    },
    bottomDetailsBox: {
      margin: "20px 0",
      backgroundColor: "#f7f7f7",
      border: "1px solid #d9d9d9",
      padding: "20px",
      borderRadius: "8px",
    },
    currBox: {
      ...styles.flexDRS,
    },
    paymentCard: {
      border: "1px solid #000",
      padding: "30px 20px",
      borderRadius: "8px",
      width: "auto",
    },
    merchantCard: {
      border: "1px solid #000",
      padding: "30px 20px",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      // cursor:"pointer"
    },
    centerItemBox: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
    },
    resentOtpText: {
      textDecorationLine: "underline",
      cursor: "pointer",
      padding: "20px 0",
      "&:hover": {
        color: pallete.parimayBlue,
      },
    },
    inputBoxes: {
      padding: "10px",
      margin: "20px 10px",
    },
    otpBoxInput: {
      ...styles.flexDRS,
      justifyContent: "center",
    },
    submitBtnOutloined: {
      width: "100%",
      padding: "12px 0",
      margin: "10px",
      textTransform: "none",
      backgroundColor: "transparent",
      border: `1px solid ${pallete.primaryBlack}`,
      color: pallete.primaryBlack,
    },
    btnsBox: {
      ...styles.flexDRS,
      width: "100%",
    },
    routeName: {
      paddingLeft: "10px",
      cursor: "pointer",
      color: pallete.primaryPurple,
      "&:hover": {
        color: pallete.parimayBlue,
        textDecorationLine: "underline",
        transition: "ease-in",
      },
    },
    bottomText: {
      display: "flex",
      flexDirection: "row",
      paddingTop: "10px",
    },
    formHeading: {
      fontWeight: 700,
      lineHeight: "44.8px",
      color: pallete.primaryBlack,
      padding: "10px 0",
    },
    bottomBtns: {
      width: "100%",
      padding: "10px 20px",
      margin: "10px 5px",
      borderRadius: "2px",
      background: "#FFF",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      textTransform: "none",
    },
    iconGA: {
      height: "18px",
      width: "18px",
    },
    bottomBTNBox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    orText: {
      fontWeight: "500",
      lineHeight: "28px",
      color: "#36205F80",
    },
    termsText: {
      color: pallete.primaryBlack,
      textDecorationLine: "underline",
      margin: "auto 0",
      cursor: "pointer",
    },
    checkBoxB: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    submitBtn: {
      width: "100%",
      padding: "12px 0",
      margin: "10px 0",
      textTransform: "none",
      cursor: "pointer",
    },
    errorMsg: {
      color: "red",
      paddingLeft: "5px",
    },
    inputBox: {
      width: "100%",
      margin: "10px 0",
    },
    headingBox: {
      backgroundColor: pallete.primaryPurple,
      padding: "10px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    },
    formBox: {
      margin: "20px 0",
    },
    imagBoxOther: {
      height: "30px",
      padding: "10px",
    },
    imagBox: {
      display: "flex",
      flexDirection: "row",
    },
    btnS10: {
      border: "none",
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 600,
      borderRadius: 0,
      "&:hover": {
        backgroundColor: pallete.primaryWhite,
        color: pallete.primaryPurple,
      },
    },
    btnS11: {
      margin: "20px 0",
      border: "none",
      borderRadius: 0,
      backgroundColor: pallete.primaryWhite,
      color: pallete.primaryPurple,
      textTransform: "none",
      fontSize: "16px",
      fontWeight: 600,
      "&:hover": {
        backgroundColor: pallete.primaryPurple,
        color: pallete.primaryWhite,
      },
    },
    buttonBoxS1: {
      backgroundColor: pallete.primaryPurple,
      width: "250px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      padding: "10px 0",
      marginTop: "20px",
    },
    innerBoxS1: {
      padding: "30px 100px",
      display: "flex",
      flexDirection: "column",
      height: "90%",
      justifyContent: "space-between",
      [theme.breakpoints.down("md")]: {
        padding: "15px 30px",
      },
    },
    innerBoxOther: {
      margin: "10px 40px",
      padding: "30px 100px",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      [theme.breakpoints.down("md")]: {
        padding: "15px",
      },
    },
    innerBoxS: {
      padding: "30px 100px",
      display: "flex",
      flexDirection: "column",
      height: "100%",
      justifyContent: "space-between",
      // borderLeft: "1px solid #000",
      margin: "10px 40px",
      [theme.breakpoints.down("md")]: {
        margin: "10px 30px",
        padding: "15px",
      },
    },
    innerBoxSignIn: {
      padding: "30px 100px",
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "center",
      // borderLeft: "1px solid #000",
      margin: "10px 40px",
      [theme.breakpoints.down("md")]: {
        margin: "10px 30px",
        padding: "15px",
      },
    },
    bottomTextS1: {
      color: pallete.primaryWhite,
    },
    headingTypo: {
      color: pallete.primaryWhite,
    },
    iconWeb: {
      alignSelf: "flex-start",
      height: "45px",
    },
    successIcon: {
      height: "100px",
    },
    mainBoxStepOne: {
      backgroundColor: pallete.primaryBlack,
      width: "100%", // specify width
      height: "100vh", // specify height
      flexShrink: 0, // apply flex-shrink
      backgroundImage: `url("assets/images/logingleftBG.svg")`, // set background image
      backgroundSize: "cover", // cover the container with the background image
      backgroundPosition: "center", // center the background image
      [theme.breakpoints.down("md")]: {
        // display: "none",
      },
    },
    footerDivider: {
      width: "100%",
      backgroundColor: pallete.primaryBlack,
    },
    footerBox: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: "10px",
      padding: "20px",
    },
    footerContent: {
      textAlign: "center",
      fontSize: "12px",
      fontStyle: "italic",
    },

    footerCopyright: {
      fontWeight: "bold",
    },

    mainCard: {
      padding: "20px",
      borderRadius: "10px",
      boxShadow: "0px 0px 8px 0px rgba(80, 48, 141, 0.40)",
      marginBottom: "10px",
      gap: "20px",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
        maxWidth: "100%",
      },
    },

    midBoxC0: {
      display: "flex",
      justifyContent: "flex-end",
      paddingBottom: "5px",
    },

    innerCard: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      minWidth: "400px",
      gap: "12px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "100%",
      },
    },
    // midBoxC1: {
    //   display: "flex",
    //   flexDirection: "column",
    //   alignItems: "center",
    //   backgroundColor: "rgba(60, 60, 60, 0.05)",
    //   padding: "20px",
    //   borderRadius: "10px",
    //   gap: "10px",
    //   width: "100%",
    //   [theme.breakpoints.down("sm")]: {
    //     maxWidth: "100%",
    //     padding: "10px",
    //   },
    // },
    listDataBox: {
      display: "flex",
      justifyContent: "space-between",
    },
    titleC0: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
      gap: "4px",
      flexWrap: "wrap",
    },
    midBoxC2: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "10px",
    },
    ArrowIconBox: {
      display: "flex",
      justifyContent: "flex-end",
      width: "100%",
    },
    collapseBox: {
      display: "flex",
      flexDirection: "column",
      width: "100%",
    },
    divider: {
      width: "100%",
      backgroundColor: "rgba(60, 60, 60, 0.1)",
    },
    doneButton: {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      color: theme.palette.primary.contrastText,
      padding: "10px 20px",
      borderRadius: "10px",
      width: "100%",
      fontWeight: "bold",
    },
    settlePaymentButton: {
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      color: theme.palette.primary.contrastText,
      padding: "10px 20px",
      borderRadius: "10px",
      width: "100%",
      fontWeight: "bold",
    },
    keepButton: {
      textDecoration: "underline",
      cursor: "pointer",
    },
    currencyBox: {
      width: "100%",
      display: "flex",
      gap: "15px",
      alignItems: "flex-end",
    },
    currencyInput: {
      flexGrow: 1,
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        { display: "none" },
      textAlign: "right",
      min: 0,
    },
    currencySelect: {
      flexGrow: 1,
    },
    currencySkeleton: {
      width: "100%",
      height: "70px",
      borderRadius: "5px",
    },

    cryptoBox: {
      width: "100%",
      display: "flex",
      gap: "15px",
      alignItems: "flex-end",
      // flexDirection: "Cc",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "row",
        gap: "5px",
        alignItems: "flex-start",
      },
    },
    // cryptoBox1: {
    //   width: "100%",
    //   display: "flex",
    //   gap: "15px",
    //   alignItems: "flex-end",
    //   // flexDirection: "Cc",
    //   [theme.breakpoints.down("sm")]: {
    //     flexDirection: "column",
    //     gap: "5px",
    //     alignItems: "flex-start",
    //   },
    // },

    cryptoBox1: {
      borderColor: "3px solid black",
      width: "100%",
      display: "flex",
      gap: "15px",
      alignItems: "flex-end",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: "5px",
        alignItems: "flex-start",
      },
    },

    midBoxC1: {
      borderColor: "3px solid black",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      backgroundColor: "rgba(60, 60, 60, 0.05)",
      padding: "20px",
      borderRadius: "10px",
      gap: "10px",
      width: "100%",
      maxWidth: "100%", // Ensure the content stays within the box width
      [theme.breakpoints.down("sm")]: {
        padding: "10px",
      },
    },

    cryptoSelect: {
      flexGrow: 1,
    },

    cryptoSkeleton: {
      width: "100%",
      height: "70px",
      borderRadius: "5px",
    },

    cryptoInput: {
      flexGrow: 1,
      "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
        { display: "none" },
      textAlign: "right",
      min: 0,
    },
    confirmationCard: {
      padding: "20px",
      gap: "20px",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        padding: "5px",
        maxWidth: "100%",
      },
    },
    ConfirmationInnerCard: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "12px",
      minWidth: "400px",
      minHeight: "400px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
        minWidth: "100%",
      },
    },
    termsAndConditions: {
      widow: "100%",
      marginTop: "auto",
      [theme.breakpoints.down("sm")]: {
        marginTop: "20px",
        marginBottom: "20px",
      },
    },
    confirmationButton: {
      padding: "10px 20px",
      borderRadius: "10px",
      alignSelf: "flex-end",
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      width: "30%",
      marginTop: "10px",
      flexGrow: 1,
    },
    confirmationBackButton: {
      padding: "10px 20px",
      borderRadius: "10px",
      alignSelf: "flex-end",
      backgroundColor: theme.palette.secondary.main,
      "&:hover": {
        backgroundColor: theme.palette.secondary.dark,
      },
      width: "30%",
      marginTop: "10px",
      flexGrow: 1,
    },
    confirmationButtonBox: {
      display: "flex",
      gap: "20px",
      width: "100%",
    },
    alertBox: {
      display: "flex",
      justifyContent: "space-between",
    },
    alertBox1: {
      display: "flex",
      width: "100%",
      gap: "20px",
    },
    addressBox: {
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "10px",
      marginTop: "20px",
      width: "100%",
    },
    addressBox1: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        fontSize: "10px",
        gap: "10px",
      },
    },
    addressText: {
      [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
      },
    },
    walletForm: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      minWidth: "300px",
      borderTop: "1px solid rgb(224 224 224)",
      borderBottom: "1px solid rgb(224 224 224)",
      padding: "20px 0px",
    },
    walletAddRemoveBox: {
      display: "flex",
      justifyContent: "space-between",
      gap: "10px",
    },
    mainBoxForm: {
      paddingTop: "20px",
    },
    buttonBox: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "flex-end",
      padding: "20px 10px",
    },
    gridItemPadding: {
      padding: "5px 10px",
    },
    submitBtnForm: {
      padding: "10px 60px",
    },
  };
});

export { useCryptohubPaymentStyles };
