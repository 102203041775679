import React from "react";
import {Box, Card, Typography} from "@mui/material";
import {makeStyles} from "tss-react/mui";


const StatsCard = ({ title, value, color, icon } : { title: any, value: any, color: any, icon : any }) => {
  const { classes } = useStyles();

  const valueToTwoDecimal = (value: any) => {
    return parseFloat(value).toFixed(4);
  }

  return (
    <Card className={classes.commonCards}>
      <Box className={classes.cardIconContent}>
        <Box className={classes.iconContainer} style={{ backgroundColor: color }}>
          {icon}
        </Box>
        <Box className={classes.cardContent}>
          <Typography className={classes.cardTitle}>{title}</Typography>
          <Typography className={classes.cardValue}>{valueToTwoDecimal(value)}</Typography>
        </Box>
      </Box>
    </Card>
  );
}

const useStyles = makeStyles()((theme) => {
    return {
        commonCards: {
            height: "100%",
            padding: "12px",
            borderRadius: "10px",
            boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
            [theme.breakpoints.down("lg")]: {
                margin: "10px 0",
            },
        },
        iconContainer: {
            width: '50px',
            height: '50px',
            borderBottomRightRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '35px',
            boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px',
            marginLeft: '-12px',
            marginTop: '-12px',
        },
        cardIconContent: {
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            gap: theme.spacing(2),
        },
        cardContent: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            paddingTop: theme.spacing(2),
            paddingBottom: theme.spacing(2),
            flex: 1,
        },
        icon: {
            fontSize: '2.5rem',
            color: "white"
        },
        cardTitle: {
            fontSize: '0.9rem',
        },
        cardValue: {
            fontSize: '1.5rem',
            fontWeight: 'bold',
            color: theme.palette.primary.main,
        },
    };
});

export { StatsCard };
