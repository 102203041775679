/**
 * @file: Environment.ts
 * @date: 26 Feb 2024

 * @description: $
 */

import {getValue} from "./Funcs";

/** Google client ID obtained from environment variables. */
export const GOOGLE_CLIENT_ID: string = getValue("GOOGLE_CLIENT_ID");

/** SECRET KEYT FOR ENCRYPTION/DECRYPTION PROCESS. */
export const SECRET_KEY: string = getValue("SECRET_KEY");


/** Base API URL obtained from environment variables. */
export const BASE_API_URL: string = getValue("BASE_URL_API");

/** Environment name obtained from environment variables. */
export const ENV_NAME: string = getValue("ENV", "").toLowerCase();

/** Indicates whether the environment is development. */
export const IS_DEVELOPMENT: boolean = ENV_NAME.includes("dev");

/** Indicates whether the environment is testing. */
export const IS_TESTING: boolean = ENV_NAME.includes("test");

/** Indicates whether the environment is production. */
export const IS_PRODUCTION: boolean = ENV_NAME.includes("prod");


/** Base API URL obtained from environment variables. */
export const AUTENTICACAO_CLIENT_ID: string = getValue("AUTENTICACAO_CLIENT_ID");
export const AUTENTICACAO_REDIRECT_URI: string = getValue("AUTENTICACAO_REDIRECT_URI");
export const AUTENTICACAO_AUTH_URL: string = getValue("AUTENTICACAO_AUTH_URL");
