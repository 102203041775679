import React from "react";
import {TabContent} from "app/components/tabs";
import FlexHubDashboard from "./FlexHubDashboard";
import FlexHubCredentialsView from "./FlexHubCredentialsView";
import FlexHubTransactionTable from "./FlexHubTransactionTable";

const FlexHubView = () => {

    const tabHeading: string = "FlexHub";
    const tabData = [
        {label: "Dashboard", component: <FlexHubDashboard />},
        {label: "Integration", component: <FlexHubCredentialsView />},
        {label: "Orders", component: <FlexHubTransactionTable />},
    ];

    return TabContent({tabData, tabHeading});

};

export default FlexHubView;
