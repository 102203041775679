import React, { useEffect } from "react";
import {
    Box,
    Card,
    Dialog,
    Divider,
    Drawer,
    IconButton,
    Typography,
    MenuItem,
    Select,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import WalletTransactionTable from "./WalletTransactionTable";
import { useGlobalState } from "app/providers/GlobalStateProvider";
import { useUserWalletQuery } from "app/queries/UserWalletQueries";
import { SearchInput, SelectInput } from "app/components/inputs";

interface Wallet {
    id: string;
    asset_id: number;
    vault_id: string;
    wallet_id: string | null;
    wallet_address: string;
    response_data: {
        id: string;
        tag: string;
        address: string;
        legacyAddress?: string;
    };
    asset_name: string;
    balance: string[];
}
export const WalletDetailedDialog = () => {
    const { state } = useGlobalState();
    const userWalletQuery = useUserWalletQuery(state.vault.id);
    const [userWallets, setUserWallets] = React.useState<Wallet[]>([]);
    const [selectedWallet, setSelectedWallet] = React.useState<string>("");

    useEffect(() => {
        if (userWalletQuery.result) {
            const wallets = userWalletQuery.result as Wallet[];

            if (wallets.length > 0) {
                const walletOrder = JSON.parse(
                    localStorage.getItem("walletOrder") || "[]"
                );

                // Initialize sorted wallets
                let sortedWallets: Wallet[] = [];

                if (Array.isArray(walletOrder) && walletOrder.length > 0) {
                    // Sort wallets based on walletOrder
                    sortedWallets = walletOrder
                        .map((id) =>
                            wallets.find((wallet) => wallet.asset_id === id)
                        )
                        .filter(
                            (wallet): wallet is Wallet => wallet !== undefined
                        ); // Filter out unmatched IDs
                }

                // Find wallets not included in walletOrder
                const remainingWallets = wallets.filter(
                    (wallet) => !walletOrder.includes(wallet.asset_id)
                );

                // Combine sorted and remaining wallets
                const finalWallets = [...sortedWallets, ...remainingWallets];
                setUserWallets(finalWallets);
                
                if(finalWallets.length > 0) {
                    setSelectedWallet(finalWallets[0].id || "");
                }
            }
        }
    }, [userWalletQuery.result]);

    const [drawerOpen, setDrawerOpen] = React.useState(false);
    const [drawerData, setDrawerData] = React.useState<{ [key: string]: any }>(
        {}
    );

    const tableWallet = selectedWallet || (userWallets.length > 0 ? userWallets[0].id : "");

    const onTransactionClick = (data: any) => {
        setDrawerData(data);
        handleDrawerOpen();
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
    };

    const handleDrawerOpen = () => {
        setDrawerOpen(true);
    };

    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"20px"}
            width={"100%"}
        >
            <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                width={"100%"}
            >
                <Select
                    value={selectedWallet}
                    onChange={(e) => setSelectedWallet(e.target.value)}
                    sx={{
                        marginLeft: "auto",
                        width: "200px",
                    }}
                >
                    {userWallets.map((wallet) => (
                        <MenuItem key={wallet.id} value={wallet.id}>
                            {wallet.asset_name}
                        </MenuItem>
                    ))}
                </Select>
            </Box>
            <Box
                display={"flex"}
                flexDirection={"row"}
                gap={"10px"}
                width={"100%"}
            >
                <Box
                    sx={{
                        flex: 1,
                        height: "100%",
                    }}
                >
                    <WalletTransactionTable
                        key={tableWallet+state.vault.id}
                        wallet_id={tableWallet}
                        onTransactionClick={onTransactionClick}
                    />
                </Box>
                {drawerOpen && (
                    <Box
                        sx={{
                            minWidth: "30%",
                            maxWidth: "30%",
                            height: "100%",
                            padding: "20px",
                        }}
                    >
                        <Card
                            sx={{
                                borderRadius: "20px",
                                padding: "20px",
                                height: "100%",
                                boxShadow: "0px 0px 24px 0px rgba(0,0,0,0.1)",
                                display: "flex",
                                flexDirection: "column",
                                backgroundColor: "#F7F6F9",
                            }}
                            variant="outlined"
                        >
                            <Box
                                display={"flex"}
                                justifyContent={"flex-end"}
                                alignItems={"center"}
                            >
                                <Typography
                                    variant="h5"
                                    color={"#36205F"}
                                    fontWeight={"bold"}
                                    marginRight={"auto"}
                                >
                                    Transaction Details
                                </Typography>
                                <IconButton onClick={handleDrawerClose}>
                                    <Close fontSize="large" />
                                </IconButton>
                            </Box>
                            <Divider
                                sx={{
                                    marginTop: "10px",
                                    marginBottom: "15px",
                                    marginhorizontal: "5px",
                                }}
                            />
                            <Box
                                display={"flex"}
                                flexDirection={"column"}
                                gap={"20px"}
                                flexGrow={1}
                                sx={{
                                    overflowX: "auto",
                                    "::-webkit-scrollbar": {
                                        height: "5px",
                                    },
                                }}
                            >
                                {Object.keys(drawerData).map((key: string) => {
                                    return (
                                        <Box
                                            display={"flex"}
                                            gap={"5px"}
                                            flexDirection={"column"}
                                        >
                                            <Box>
                                                <Typography
                                                    variant="h6"
                                                    fontWeight={"bold"}
                                                    color={"#36205F"}
                                                >
                                                    {key} :
                                                </Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant="body1">
                                                    {drawerData[key] || "N/A"}
                                                </Typography>
                                            </Box>
                                        </Box>
                                    );
                                })}
                            </Box>
                        </Card>
                    </Box>
                )}
            </Box>
        </Box>
    );
};
