import React, {useEffect, useState} from "react";
import { T_UseFlexhubPaymentState } from "./FlexhubPaymentHooks";
import { Box, Typography, IconButton, Button, LinearProgress } from "@mui/material"; // Added CircularProgress import
import RefreshIcon from '@mui/icons-material/Refresh';
import { useFlexhubTransactionComplete, useFlexhubTransactionVerify } from "./FlexhubPaymentQueries";
import { useNotification } from "app/providers";
import { useTranslation } from "react-i18next";

type T_ViewProps = {
    classes: any;
    paymentState: T_UseFlexhubPaymentState;
    transactionId: string | any;
};

const useTimerHook = (input: number) : [number, React.Dispatch<React.SetStateAction<number>>, React.Dispatch<React.SetStateAction<boolean>>] => {
    const [timer, setTimer] = useState(input);
    const [stop, setStop] = useState(false);

    useEffect(() => {
        if (stop) {
            return;
        }

        if (timer <= 0) {
            setTimer(input);
        }

        const interval = setInterval(() => {
            setTimer(timer - 1);
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [timer]);

    return [timer, setTimer, setStop];
}

export const TransactionCompletedView = ({classes, paymentState, transactionId}: T_ViewProps): React.JSX.Element => {
    const [loading, setLoading] = useState(false);
    const [disableButton, setDisableButton] = useState(false);
    const [timer, setTimer, setStop] = useTimerHook(10);
    const { t } = useTranslation();
    const notification = useNotification();

    const walletBalanceRequest = useFlexhubTransactionVerify(paymentState.transactionLink.transaction_id);
    const usecompleteTransaction = useFlexhubTransactionComplete(paymentState.transactionLink.transaction_id);

    const walletBalance = walletBalanceRequest.result?.received_quantity || 0;

    const refreshWalletBalance = () => {
        setTimer(10);
        walletBalanceRequest.refetch();
    }

    const handleCompleteTransaction = () => {
        setStop(true);
        setDisableButton(true);
        setLoading(true);
        usecompleteTransaction.EXECUTE({
            payload: { request_uid: paymentState.transactionLink.transaction_id },
            onSuccessFn: (data, message) => {
                if(data?.redirect_url) {
                    window.location.href = data.redirect_url;
                } else {
                    setTimeout(() => {
                        handleCompleteTransaction();
                    }, 10000);
                }
            },
            onErrorFn: (data, message) => {
                setLoading(false);
                notification.error(message);
            }
        });
    }
    
    return (
        <Box className={classes.mainCard} style={{ padding: '20px', position: 'relative' }}>
            {
                loading &&
                <>
                    <LinearProgress sx={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            borderRadius: '8px 8px 0 0',
                            height: '7px',
                        }}
                    />
                    <Typography variant="body2" textAlign={"center"} style={{ marginBottom: '2px', color: '#ff9800' }}>
                        {t("Please don't close this window and wait while we complete the transaction.")}
                    </Typography>
                </>
            }
            <Typography variant="body1" style={{ color: '#666', marginLeft: "auto" }}>{t("refreshing in")} {timer} {t("seconds")}</Typography>
            {/* <Typography variant="h4" textAlign={"center"} style={{ marginBottom: '10px', color: '#333' }}>Verify Transaction</Typography> */}
            <Box className={classes.innerCard} style={{ backgroundColor: '#fff', borderRadius: '8px' }}>
                <Box className={classes.midBoxC1} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography variant="h2" style={{ color: '#4caf50', width: "100%" }} display={"flex"} alignItems={"center"}
                        sx={{
                            ['@media (max-width:600px)']: {
                                fontSize: '1.1rem',
                            }
                        }}
                    >
                        <Box marginLeft={"auto"}>
                            {t("Received Quantity")}: {walletBalance}
                        </Box>
                        <IconButton onClick={refreshWalletBalance} style={{ marginLeft: 'auto' }} disabled={disableButton}>
                            <RefreshIcon />
                        </IconButton>
                    </Typography>
                </Box>
                <Typography variant="h6" style={{ marginBottom: '10px', color: '#666' }}>
                    {t("Verify the transaction details below and click on the \"Confirm\" button to complete the transaction.")}
                </Typography>
                <Typography variant="h6" style={{ color: '#666' }}>
                    {t("If the received quantity is incorrect please wait until received quantity gets updated.")}
                </Typography>
                <Button
                    className={classes.doneButton}
                    style={{ marginTop: '20px' }}
                    onClick={handleCompleteTransaction}
                    disabled={disableButton}
                >
                    {t("Confirm")}
                </Button>
            </Box>
        </Box>
    );
};
