import { ApiConfig, createAPIURL } from "app/config";
import { RQ } from "app/hooks";
import { useEffect, useState } from "react";

const FLEXHUB_APIS = {
  TRANSACTION_STATS: ApiConfig.create(
    "user/flex-hub-transaction-request/get-totals"
  ),
  TRANSACTION_DAY_BASED_STATS: ApiConfig.create(
    "user/flex-hub-transaction-request/get-daily-totals"
  ),
  TRANSACTION_TABLE_DATA: ApiConfig.create(
    "user/flex-hub-transaction-request"
  ),
  WEBHOOK_REQUEST: ApiConfig.create(
    "user/webhook-details"
  ),
};

export type T_FlexhubTransactionStats = {
  Total: number;
  "Payment Pending": number;
  "Payment Received": number;
  "Sent To Merchant": number;
  "Settled With Merchant": number;
};

export type T_FlexhubDayBasedTransactionStats = {
  [key: string]: T_FlexhubTransactionStats;
};

// {
//   "id": "gAAAAABnSJvyHbs7rOZDZjjR7AzKTXHateO1dAejWPz58iSjMS71BwCF-b7eFJO-yJp-3AlPs1MtIrfDOxpNVbl9OROo1Py-SA==",
//   "sender_id": 2,
//   "receiver_id": 13,
//   "sender_email": "dixith1500@gmail.com", yes
//   "receiver_email": "dixith1500@gmail.com",
//   "user_id_in_merchant_site": "", yes
//   "currency_id": 2,
//   "currency_symbol": "EUR", yes
//   "language_id": 2,
//   "language_name": "Portuguese",
//   "language_symbol": "pt",
//   "verified_at": "2024-11-06T05:44:57.760668Z",
//   "asset_id": 2,
//   "fireblocks_asset_id": "USDT_BSC_TEST", yes
//   "fireblocks_wallet_address": "0xb95368A3248AE50E28587D6dab1AF428B7e81D5f",
//   "amount": 0.0, yes
//   "fee": 0.0, yes
//   "network_fee": 0.0, yes
//   "status_id": 2,
//   "status_name": "Sent To Merchant" yes 
// }

export type T_FlexhubTransactionTableData = {
  id: string;
  sender_id: number;
  receiver_id: number;
  sender_email: string;
  receiver_email: string;
  user_id_in_merchant_site: string;
  currency_id: number;
  currency_symbol: string;
  language_id: number;
  language_name: string;
  language_symbol: string;
  verified_at: string;
  asset_id: number;
  fireblocks_asset_id: string;
  fireblocks_wallet_address: string;
  amount: number;
  fee: number;
  network_fee: number;
  status_id: number;
  status_name: string;
  received_quantity: number;
};


export type T_FlexhubTransactionTableDataArray =
  Array<T_FlexhubTransactionTableData>;

export const StatsKey: Array<keyof T_FlexhubTransactionStats> = [
  "Total",
  // "Payment Pending",
  // "Payment Received",
  "Sent To Merchant",
  "Settled With Merchant",
];

const useFlexHubTransactionStatsRequest =
  (): RQ.T_QueryResult<T_FlexhubTransactionStats> => {
    return RQ.useQueryRequest<T_FlexhubTransactionStats>({
      url: FLEXHUB_APIS.TRANSACTION_STATS.url,
      queryKey: FLEXHUB_APIS.TRANSACTION_STATS.key,
    });
  };

const useFlexHubDayBasedTransactionStatsRequest = (days: number) => {
  return RQ.useQueryRequest<T_FlexhubDayBasedTransactionStats>({
    url: FLEXHUB_APIS.TRANSACTION_DAY_BASED_STATS.url,
    payload: { days },
    queryKey: [...FLEXHUB_APIS.TRANSACTION_DAY_BASED_STATS.key, days],
    method: "POST",
  });
};

const useFlexHubTransactionTableRequest = (status: Array<number>) => {
  return RQ.useQueryRequest<T_FlexhubTransactionTableDataArray>({
    url: FLEXHUB_APIS.TRANSACTION_TABLE_DATA.url,
    payload: { status },
    queryKey: [...FLEXHUB_APIS.TRANSACTION_TABLE_DATA.key, ...status],
    method: "POST",
  });
};

type T_FlexhubWebhookRequest = {
  new_transaction_webhook: string;
  minimum_amount_for_new_transaction: number;
};

const useFlexhubWebhookRequest = () => {
  return RQ.useQueryRequest<T_FlexhubWebhookRequest>({
    url: FLEXHUB_APIS.WEBHOOK_REQUEST.url,
    queryKey: FLEXHUB_APIS.WEBHOOK_REQUEST.key,
    refetchOnWindowFocus: false,
  });
}

const useFlexhubWebhookPutRequest = (): RQ.T_MutationResult<T_FlexhubWebhookRequest> => {
  return RQ.usePutRequest<T_FlexhubWebhookRequest>({
      url: FLEXHUB_APIS.WEBHOOK_REQUEST.url,
  });
}

export {
  useFlexHubTransactionStatsRequest,
  useFlexHubDayBasedTransactionStatsRequest,
  useFlexHubTransactionTableRequest,
  useFlexhubWebhookRequest,
  useFlexhubWebhookPutRequest,
};
