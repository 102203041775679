import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, Divider, FormControlLabel, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFlexPaymentStyles } from "./FlexhubPaymentStyles";
import { T_UseFlexhubPaymentState } from "./FlexhubPaymentHooks";
import { Link } from "react-router-dom";
import { useFlexhubTransactionRequest } from "./FlexhubPaymentQueries";
import { useLocalization } from "app/hooks";
import Loader from "app/components/unsorted/Loader";
import { getAxiosAPI, postAxiosAPI } from "app/utils/axiosApiRequests";
import { Formik } from "formik";
import { SearchInput } from "app/components/inputs";
import * as Yup from "yup";

const TranslatedForm_schema = () => {
  const { t } = useTranslation();
  const form_schema = Yup.object().shape({
    asset: Yup.string().required("Asset is required"),
  });
  return form_schema;
}

const getIcon = (assetId: string) => {
  switch (assetId) {
      case "USDT Etherum":
          return "assets/icons/usdt.svg";
      case "Etherum":
          return "assets/icons/eth.svg";
      case "Bitcoin":
          return "assets/icons/btc.svg";
      default:
          return "assets/icons/eth.svg";
  }
};

const FlexhubTermsView = ({ paymentState } : { paymentState: T_UseFlexhubPaymentState }) => {
  const { t } = useTranslation();
  const { classes } = useFlexPaymentStyles();
  const [isChecked, setIsChecked] = useState(false);
  const transactionRequest = useFlexhubTransactionRequest(paymentState.transactionId);
  const { initializeLanguage } = useLocalization();
  const [assets, setAssets] = useState([])

  const form_schema = TranslatedForm_schema();

  useEffect(() => {
    const initLang = async () => {
      if (!transactionRequest.result) return;

      let languageSymbol =  transactionRequest.result.language_symbol;

      if (languageSymbol === "zh-CN"){
        languageSymbol = "zh";
      }

      if (!["en", "pt", "zh"].includes(languageSymbol)) {
        languageSymbol = "en";
      }

      sessionStorage.setItem("userLanguage", languageSymbol);
      initializeLanguage(languageSymbol);
    };

    initLang();
  }, [transactionRequest.result?.language_symbol]);

  const getAsset = async () => {
    await getAxiosAPI("/asset")
      .then((res: any) => {
        console.log(res.data.data);
        setAssets(res.data.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAsset();
  }, []);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked);
  };

  const handleProceed = (values: any) => {
    paymentState.update({ isTermsConfirmed: true, asset: values.asset });
  };

  if(transactionRequest.isResolving) {
    return <Loader isLoading={true} />
  }

  return (
    <Box className={classes.mainCard}>
      <Box className={classes.confirmationCard}>
        <Formik
          initialValues={{
            asset: "",
          }}
          validationSchema={form_schema}
          onSubmit={(values: any) => {
            handleProceed(values);
          }}
        >
          {({
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            setFieldValue,
          }: any) => (
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                  marginTop: "10px",
                  width: "100%",
                  alignItems: "center",
                  '@media (max-width: 600px)': {
                    flexDirection: "column",
                    gap: "0px",
                  },
                  backgroundColor: "#f3f3f3",
                  borderRadius: "10px",
                }}
                paddingInline={"40px"}
                paddingY={"15px"}
              >
                <Typography variant="h5" fontWeight={"bold"}>{t("Select Asset")} :</Typography>
                <Box sx={{ flex: 1 ,width: "100%"}} marginInline={"20px"}>
                  <SearchInput
                    options={assets.filter((asset: any) => asset.is_crypto_hub_asset)}
                    label={t("Select Asset")}
                    onSelect={(field: any, value: any) =>
                      setFieldValue(field, value)
                    }
                    displayValue="name"
                    formikValue="asset"
                    // renderOption={(props: any, option: any) => (
                    //   <Box
                    //       component="li"
                    //       sx={{
                    //           "& > img": { mr: 2, flexShrink: 0 },
                    //       }}
                    //       {...props}
                    //   >
                    //     <img
                    //       // component="img"
                    //       width={"24px"}
                    //       height={"24px"}
                    //       src={getIcon(option.name)}
                    //       alt="Descriptive Alt Text"
                    //     />
                    //     <Typography variant="body1" marginLeft={"10px"}>
                    //       {option.name}
                    //     </Typography>
                    //   </Box>
                    // )}
                  />
                </Box>
              </Box>
              {touched.asset && errors.asset && (
                <Typography
                  variant="subtitle2"
                  align="center"
                  className={classes.errorMsg}
                >
                  {t(errors.asset)}
                </Typography>
              )}
              <Divider sx={{
                marginTop: "20px",
                '@media (max-width: 600px)': {
                  marginTop: "10px",
                },
              }}/>
              <Box display="flex" alignItems="center" justifyContent="center" mt={4}>
                  <Typography variant="h4" component="div" >
                      {t("Disclaimer")}
                  </Typography>
              </Box>
              <Box className={classes.termsAndConditions}>
                <Typography variant="h6"
                  sx={{
                    '@media (max-width: 600px)': {
                      fontSize: "14px",
                      fontWeight: "normal",
                    },
                  }}
                >1. {t("Due to conversion fees and network fees, transfers of 10 euros or less may not be credited to your account.")}</Typography>
                <Typography variant="h6" marginTop={"5px"}
                  sx={{
                    '@media (max-width: 600px)': {
                      fontSize: "14px",
                      fontWeight: "normal",
                    },
                  }}
                >
                  2. {t("Once you proceed, asset cannot be changed.")}
                </Typography>
                <Typography variant="h6" marginTop={"5px"}
                  sx={{
                    '@media (max-width: 600px)': {
                      fontSize: "14px",
                      fontWeight: "normal",
                    },
                  }}
                >
                    3. {t("Please read and accept our terms and conditions to proceed.")}
                  <span>
                    {" "}
                    <Link to="/privacy-policy" target="_blank">
                      {t("Terms and Conditions")}
                    </Link>
                  </span>
                </Typography>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      name="termsCheckbox"
                      color="primary"
                    />
                  }
                  label={t("I accept the terms and conditions")}
                />
              </Box>
              <Box className={classes.confirmationButtonBox}>
                <Button
                  className={classes.confirmationButton}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                  disabled={!isChecked}
                >
                  {t("Proceed")}
                </Button>
              </Box>
            </Box>
          )}
        </Formik>
      </Box>
    </Box>
  );
};

export default FlexhubTermsView;
