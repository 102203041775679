import React from "react";
import { Box, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../Style";
import {
  AnimatedButton,
  AnimatedText,
  AnimatedToolTip,
} from "../animation/HomeSecAnimation";
import { useAssetsList } from "app/queries/CryptoQueries";

const Home = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();

  const text1 = "Easy to Receive, Send & Withdraw".split(" ");
  const text2 = "Cryptocurrency".split(" ");
  const text3 = "for Your Business".split(" ");
  const text4 = " Get Started";

  return (
    <Grid container className={classes.gridContainer0}>
      <Grid item lg={6} xs={12} md={6} className={classes.leftGrid0}>
        <AnimatedText
          text1={text1}
          text2={text2}
          text3={text3}
          classes={classes}
        />
        <AnimatedButton
          classes={classes}
          onClick={() => navigate("/signup")}
          text4={text4}
        />
      </Grid>

      <Grid item lg={6} xs={12} md={6} sx={{}} className={classes.leftGrid1}>
        <Box
          component="img"
          className={classes.Graph}
          src="/assets/images/Graph.png"
          alt="Graph"
        />
        <AnimatedToolTip classes={classes} />
      </Grid>
    </Grid>
  );
};

export default Home;
