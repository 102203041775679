// import React from 'react';
// import { Box, Typography } from '@mui/material';
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CurrencyBitcoinIcon from "@mui/icons-material/CurrencyBitcoin";
import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { motion } from "framer-motion";
import { pallete } from "app/styles";
import { DashboardCardSecondary } from "app/components/unsorted";
import { TooltipCard } from "app/components/unsorted/Cards";
import { useAssetsList } from "app/queries/CryptoQueries";
import { makeStyles } from "tss-react/mui";
import { styles } from "app/styles";

type T_Animation = {
  text1: string[];
  text2: string[];
  text3: string[];
  classes: {
    leftHeading0: string;
  };
};
type T_Animationbtn = {
  text4: string;
  onClick: () => void;
  classes: {
    btn0: string;
  };
};
type T_AnimationTooltip = {
  classes: {
    minusRating: string;
    plusRating: string;
    GraphTooltip: string;
  };
};
// type T_AnimationTooltip1 = {
//   className: {
//     GraphTooltip: string;
//   };
// };

type ArrofType = T_Animation[];

export const AnimatedText = ({ text1, text2, text3, classes }: T_Animation) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ ease: "easeOut", duration: 2 }}
  >
    <Typography variant="h1" className={classes.leftHeading0}>
      {text1.map((el, i) => (
        <motion.span
          key={i}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
            delay: i / 5,
            repeat: Infinity,
            repeatDelay: 3,
            repeatType: "mirror",
          }}
        >
          {el}{" "}
        </motion.span>
      ))}
      <span style={{ color: pallete.selections.green }}>
        {text2.map((el, i) => (
          <motion.span
            key={i}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{
              duration: 0.5,
              delay: (text1.length + i) / 5,
              repeat: Infinity,
              repeatDelay: 3,
              repeatType: "mirror",
            }}
          >
            {el}{" "}
          </motion.span>
        ))}
      </span>
      {text3.map((el, i) => (
        <motion.span
          key={i}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{
            duration: 0.5,
            delay: (text1.length + text2.length + i) / 5,
            repeat: Infinity,
            repeatDelay: 3,
            repeatType: "mirror",
          }}
        >
          {el}{" "}
        </motion.span>
      ))}
    </Typography>
  </motion.div>
);

export const AnimatedToolTip = ({ classes }: T_AnimationTooltip) => {
  // useStyles1();

  const cryptoAssets: any = useAssetsList();
  console.log(cryptoAssets.result);
  const isGrowing = (amt: number) => {
    if (amt > 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <motion.div
      initial={{ opacity: 0, scale: 1 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{
        duration: 3,
        repeat: Infinity,
        repeatType: "reverse",
        repeatDelay: 1.5,
      }}
      className={classes.GraphTooltip}
    >
      {/* <Box component="img" src="/assets/images/GraphTooltip.png" alt="Graph" /> */}
      <TooltipCard
        // limit={2}
        //  mdSize={3}
        key={"gridsec1"}
      />
      {/* {cryptoAssets.result.slice(0, 1).map((data: any, index: any) => (
        <TooltipCard
          cryptoSymbol="BTC"
          cryptoName="BTC"
          percentageChange={0.7}
          price={
            isGrowing(parseFloat(data.changePercent24Hr))
              ? classes.plusRating
              : classes.minusRating
          }
        />
      ))} */}
      {/* <TooltipCard
        cryptoSymbol="BTC"
        cryptoName="BTC"
        percentageChange={0.7}
        price={"83"}
      /> */}
    </motion.div>
  );
};

export const AnimatedButton = ({ classes, onClick, text4 }: T_Animationbtn) => {
  // const classes = useStyles();

  return (
    <motion.div
      whileHover={{ scale: 0.94 }}
      whileTap={{ scale: 0.94 }}
      style={{ width: 194.48 }}
    >
      <Button className={classes.btn0} onClick={onClick}>
        {text4}
      </Button>
    </motion.div>
  );
};

// export default AnimatedText;
// const useStyles1 = makeStyles()((theme) => {
//   return {
//     topCard1: {
//       padding: "0 20px 10px 0",
//       [theme.breakpoints.down("lg")]: {
//         padding: "10px",
//       },
//     },
//     ratingAmtP: {
//       color: "#00E069",
//       fontWeight: 600,
//     },
//     ratingAmtN: {
//       color: "#E13A05",
//       fontWeight: 600,
//     },
//     plusRating: {
//       borderRadius: "4px",
//       backgroundColor: "#E5FFF1",
//       display: "flex",
//       flexDirection: "row",
//       justifyContent: "center",
//       alignItems: "center",
//       alignContent: "center",
//       padding: "2px 5px",
//     },
//     minusRating: {
//       borderRadius: "4px",
//       backgroundColor: "#FFF0EB",
//       display: "flex",
//       flexDirection: "row",
//       justifyContent: "center",
//       alignItems: "center",
//       alignContent: "center",
//       padding: "2px 5px",
//     },
//     ratingbox: {
//       display: "flex",
//       flexDirection: "row",
//     },
//     grphnIcons: {
//       height: "15px",
//       width: "15px",
//       padding: "2px",
//     },
//     secondCard: {
//       padding: "12px",
//       // margin: "0 20px 20px 0",
//       height: "99px",
//       borderRadius: "8px",
//       boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
//       display: "flex",
//       flexDirection: "column",
//       justifyContent: "space-between",
//     },
//     card2Box1: {
//       ...styles.flexDRC,
//       justifyContent: "space-between",
//     },
//     centerImg: {
//       height: "32px",
//       width: "32px",
//       margin: "auto 10px",
//     },
//     midBoxC0: {
//       ...styles.flexDCC,
//       height: "70%",
//       // alignItems:"center",
//       // alignContent:"center",
//     },
//     midBoxC1: {
//       ...styles.flexDRC,
//     },
//     primaryCard: {
//       padding: "12px 0",
//       borderRadius: "8px",
//       boxShadow: "0px 0px 24px 0px rgba(42, 23, 76, 0.08)",
//       height: "244px",
//       width: "100%",
//       // marginRight: "20px",
//     },
//   };
// });
